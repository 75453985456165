export default {
  data() {
    return {
      show_pay: false,
      table_loading: true, // table loading
      rate_loading: true, // 汇率loading
    };
  },
  methods: {
    transTxt(str) {
      str.split('');
      let arr = str.split('');
      arr.splice(3, 0, '/');
      return arr.join('');
    },
    formatAmount(obj, key) {
      if (obj) {
        return this.$utils.formatAmount(obj[key]);
      }
      return '-';
    },
    changeTableLoading(flag) {
      this.table_loading = flag;
    },
    changeRateLoading(flag) {
      this.rate_loading = flag;
    },
    findImg(currency) {
      return require(`@/static/images/${currency}.png`);
    },
    transCurrencyToTxt(currency) {
      switch (currency) {
        case 'USD':
          return '美元';
        case 'AUD':
          return '澳元';
        case 'CAD':
          return '加元';
        case 'CNH':
          return '离岸人民币';
        case 'EUR':
          return '欧元';
        case 'GBP':
          return '英镑';
        case 'HKD':
          return '港币';
        case 'JPY':
          return '日元';
        case 'NZD':
          return '纽元';
        case 'CHF':
          return '瑞郎';
        case 'SGD':
          return '新加坡元';
        case 'SEK':
          return '瑞典克朗';
      }
    },
  },
};
