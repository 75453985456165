import request from '@/utils/request';

/* 首页默认币种汇率 */
export const getHomeExchangeRate = params =>
  request.get(`/exchange-api/trade/show`, { params });

/* 查询钱包数据 */
export const getAllAcc = params =>
  request.get(`/exchange-api/wallet/get`, { params });

/* 获取账户余额 */
export const getRemainAmount = () => request.get(`/exchange-api/limit/get`, {});

// 获取待办事项
export const getPendingList = params =>
  request.get('/exchange-api/report/notify', { params });

// 查询兑换记录
export const getExchangeRecordList = params =>
  request.get('/exchange-api/trade/getDetail', { params });
