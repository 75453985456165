<template>
  <div class="_container">
    <!--左边-->
    <div class="_left">
      <div class="header">
        <div class="header_icon_box">
          <span class="icon iconfont icon-zhanghuyue"></span>
        </div>
        <div class="content_box">
          <p class="txt_info">
            <span>{{ $t('home_page_title1') }}</span>
            <span>{{ $t('other_title4') }} {{ time }}</span>
          </p>
          <p class="acc_info">
            <a @click="$router.push('/my-account')">
              {{ $t('home_page_title3') }}
            </a>
            <a @click="show_pay = true">{{ $t('home_page_title4') }}</a>
          </p>
        </div>
      </div>
      <div class="content">
        <el-table size="small" :data="account_data" style="width: 100%">
          <el-table-column
            prop="currency"
            :label="$t('home_page_title5')"
            align="center"
            width="160"
          >
            <template slot-scope="scope">
              <div class="currency-column">
                <img
                  alt="c_icon"
                  style="width: 28px; height: 28px"
                  :src="findImg(scope.row.currency.toLowerCase())"
                />
                &nbsp;
                <span style="white-space: nowrap; text-overflow: ellipsis">
                  {{ transCurrencyToTxt(scope.row.currency) }}
                </span>
                &nbsp;
                <span style="white-space: nowrap; text-overflow: ellipsis">
                  {{ scope.row.currency }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="balance"
            :label="$t('home_page_title6')"
            align="center"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="frozenStatus"
            :label="$t('home_page_title7')"
            align="center"
            width="160"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.frozenStatus === 0
                    ? $t('system_settings_text4')
                    : $t('system_settings_text5')
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="action"
            :label="$t('home_page_title8')"
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <a
                @click="
                  $router.push(`/check-detail?currency=${scope.row.currency}`)
                "
              >
                {{ $t('home_page_title9') }}
              </a>
              &nbsp;
              <a @click="$router.push(`/withdraw`)">
                {{ $t('home_page_title10') }}
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--右边-->
    <div class="_right">
      <div class="top">
        <p class="header">
          <span>
            <i class="icon iconfont icon-jiaoyishuju"></i>
          </span>
          <span>{{ $t('home_page_title11') }}</span>
          <a
            style="position: absolute; right: 8%"
            @click="$router.push('/credit-record-detail')"
          >
            {{ $t('home_page_title12') }}
          </a>
        </p>
        <div class="content">
          <p>
            <span>{{ $t('home_page_title13') }}</span>
            <span>{{ formatAmount(remain_obj, 'limit') }}</span>
          </p>
          <p>
            <span>{{ $t('home_page_title14') }}</span>
            <span>{{ formatAmount(remain_obj, 'used') }}</span>
          </p>
          <p>
            <span>{{ $t('home_page_title15') }}</span>
            <span>{{ formatAmount(remain_obj, 'balance') }}</span>
          </p>
        </div>
      </div>
      <div class="middle">
        <div class="header">
          <span>
            <i class="icon iconfont icon-jiaoyishuju"></i>
          </span>
          <span>{{ $t('home_page_title16') }}</span>
        </div>
        <div class="content">
          <el-table size="small" :data="record_list">
            <el-table-column
              :label="$t('home_page_title17')"
              prop="dealDate"
              min-width="130px"
            ></el-table-column>
            <el-table-column
              :label="$t('home_page_title18')"
              prop="ccyPair"
              width="120"
            ></el-table-column>
            <el-table-column
              :label="$t('home_page_title19')"
              prop="txnData"
              width="136"
            ></el-table-column>
            <el-table-column
              :label="$t('home_page_title20')"
              prop="contraData"
              width="136"
            ></el-table-column>
            <el-table-column
              :label="$t('home_page_title21')"
              prop="rate"
              width="136"
            ></el-table-column>
            <el-table-column
              :label="$t('home_page_title22')"
              prop="status"
              width="136"
            >
              <template slot-scope="scope">
                {{ statusArr[scope.row.status] || '-' }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom">
        <div class="header">
          <span>
            <i class="icon iconfont icon-daiban"></i>
          </span>
          <span>{{ $t('home_page_title23') }}</span>
        </div>
        <div class="content">
          <div class="todo-list">
            <p>
              {{ $t('home_page_title24') }}：{{
                this.pending_list_obj.tradeNumber || 0
              }}
            </p>
            <p>
              {{ $t('home_page_title25') }}：{{
                this.pending_list_obj.feeNumber || 0
              }}
            </p>
            <p>
              {{ $t('home_page_title26') }}：{{
                this.pending_list_obj.nextDayInfo || '-'
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <a-modal
      :visible="show_pay"
      @cancel="show_pay = false"
      :title="$t('home_page_title4')"
      :width="900"
    >
      <template slot="footer">
        <a-button type="primary" @click="show_pay = false">
          {{ $t('home_page_title27') }}
        </a-button>
      </template>
      <a-descriptions bordered :column="2" :title="$t('home_page_title28')">
        <a-descriptions-item :label="$t('home_page_title29')">
          DBS BANK (HK) LIMITED
        </a-descriptions-item>
        <a-descriptions-item label="SWIFT代码">DHBKHKHH</a-descriptions-item>
        <a-descriptions-item :label="$t('home_page_title30')">
          18/F, The Center, 99 Queen’s Road Central, Central, Hong Kong
        </a-descriptions-item>
        <a-descriptions-item :label="$t('home_page_title31')">
          {{ this.remain_obj && this.remain_obj.name }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('home_page_title32')">
          {{ this.remain_obj && this.remain_obj.address }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('home_page_title33')">
          {{ (this.remain_obj && this.remain_obj.account) || '-' }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('home_page_title34')">
          {{ this.remain_obj && this.remain_obj.companyId }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('home_page_title35')">
          {{ $t('home_page_title36') }}
        </a-descriptions-item>
        <a-descriptions-item :label="$t('home_page_title37')">
          {{ $t('home_page_title38') }}
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
import mixins from './mixins';
import {
  // getHomeExchangeRate,
  getAllAcc,
  getRemainAmount,
  getPendingList,
  getExchangeRecordList,
} from './api';
import moment from 'moment';
import { mapMutations } from 'vuex';
export default {
  name: 'home-main',
  mixins: [mixins],
  data() {
    return {
      account_data: [],
      rate_data: [],
      remain_obj: null,
      // exchange_rate: [],
      pending_list_obj: [],
      record_list: [],
      record_list_credit: [],
      time: '',
      // 兑换记录pagination
      pages: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
    };
  },
  computed: {
    statusArr() {
      const language = {
        cn: [
          '未交割',
          '已交割',
          '交割失败',
          '已违约',
          '已受理',
          '交割中',
          '锁汇中',
          '锁汇失败',
        ],
        en: [
          'Undelivered',
          'Delivered',
          'Delivery failure',
          'Breach of contract',
          'Admissible',
          'Delivery',
          'Lockhart Exchange',
          'Failure to lock the exchange',
        ],
      };
      return language[this.$i18n.locale()];
    },
  },
  mounted() {
    this.time = moment().format('YYYY-MM-DD HH:mm:ss');
    this._getAllAcc(); // 获取全部账户
    this._getHomeExchangeRate(); // 实时查询外汇外卖
    this._getRemainAmount(); // 查询账户余额
    this._getPendingList(); // 查询待办事项
    this._getExchangeRecordList(); // 查询近期兑换记录
  },
  methods: {
    ...mapMutations(['setCompanyId']),
    async _getHomeExchangeRate() {
      this.changeRateLoading(true);
      //const _params = {};
      // this.exchange_rate = await getHomeExchangeRate(_params);
      this.changeRateLoading(false);
    },
    async _getAllAcc() {
      this.changeTableLoading(true);
      const _params = {};
      this.account_data = await getAllAcc(_params);
      this.changeTableLoading(false);
    },
    async _getRemainAmount() {
      this.remain_obj = await getRemainAmount();
      this.setCompanyId(this.remain_obj.companyId);
    },
    async _getPendingList() {
      this.pending_list_obj = await getPendingList({});
    },
    async _getExchangeRecordList() {
      const { pageSize, pageNum } = this.pages;
      const _params = {
        pageSize,
        pageNum,
      };
      const { dataSet } = await getExchangeRecordList(_params);
      this.record_list = dataSet.slice(0, 3);
    },
  },
};
</script>

<style scoped lang="scss">
._container {
  width: 100%;
  background: #f0f2f5;
  height: 100%;
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  ._left {
    width: 45%;
    box-sizing: border-box;
    display: flex;
    align-items: baseline;
    flex-direction: column;
    background: #ffffff;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0px 0px 4px 0px rgba(0, 21, 41, 0.12);
    .header {
      position: relative;
      height: 73px;
      width: 100%;
      border-bottom: 1px solid #e9e9e9;
      box-sizing: border-box;
      padding: 8px 16px 16px 16px;
      .header_icon_box {
        position: absolute;
        display: inline-block;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #1890ff;
        & > span {
          position: absolute;
          height: 100%;
          width: 100%;
          color: #fff;
          font-size: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .content_box {
        display: flex;
        margin-left: 38px;
        justify-content: space-between;
        align-items: flex-end;
        .txt_info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 12px;
          & > span:first-child {
            font-size: 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
          }
          & > span:nth-child(2) {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            margin-top: 4px;
          }
        }
        .acc_info {
          a {
            margin-right: 10px;
          }
        }
      }
    }
    .content {
      width: 100%;
      height: calc(100% - 60px);
      .currency-column {
        display: flex;
        align-items: center;
      }
    }
  }
  ._right {
    width: 55%;
    margin-left: 16px;
    .top {
      height: 145px;
      .header {
        display: flex;
        align-items: center;
        position: relative;
      }
      .content {
        display: flex;
        justify-content: space-around;
        & > p {
          span {
            display: block;
          }
          span:nth-child(2) {
            margin-top: 8px;
            font-size: 20px;
          }
        }
        & > p:nth-child(2) {
          span:nth-child(2) {
            color: #4dcb73;
          }
        }
        & > p:nth-child(3) {
          span:nth-child(2) {
            color: #f5222d;
          }
        }
      }
    }
    .middle {
      margin-top: 16px;
      box-sizing: border-box;
      padding: 0 10px;
      .header {
        display: flex;
        align-items: center;
      }
      .content {
        margin-top: 10px;
        & > div {
          p {
            display: flex;
            justify-content: space-around;
            margin-bottom: 12px !important;
          }
        }
        .spin {
          text-align: center;
          border-radius: 4px;
          padding: 40px 60px;
          margin: 20px 0;
        }
      }
    }
    .bottom {
      height: 261px;
      margin-top: 16px;
      .header {
        display: flex;
        align-items: center;
      }
      .content {
        box-sizing: border-box;
        padding: 0 10px 10px 10px;
        .todo-list {
          p {
            margin-top: 20px;
          }
        }
      }
    }
    .top,
    .middle,
    .bottom {
      width: 100%;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(0, 21, 41, 0.12);
    }
    //公共样式
    .header {
      height: 56px;
      width: 100%;
      box-sizing: border-box;
      padding: 12px;
      position: relative;
      border-bottom: 1px solid #e9e9e9;
      & > span:first-child {
        display: inline-block;
        background: #975fe4;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        line-height: 34px;
        text-align: center;
        position: absolute;
        i {
          position: absolute;
          font-size: 28px;
          display: inline-block;
          color: #fff;
          left: 2px;
          bottom: 2px;
        }
      }
      & > span:nth-child(2) {
        margin-left: 50px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 16px;
      }
      & > span:nth-child(3) {
        position: absolute;
        bottom: 0;
        left: 60px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
      }
    }
  }
  .my_icon {
    color: #1890ff;
    font-size: 30px;
    margin-right: 12px;
  }
}
::v-deep.cell {
  min-width: 138px !important;
}
@media screen and (max-width: 1400px) {
  ._container {
    display: block;
    ._left {
      width: 100%;
      margin-bottom: 20px;
    }

    ._right {
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>
